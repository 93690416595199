* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
}

.projects-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 50vw;
    // margin-left: 30%;
    animation: fadeIn 1s 1.8s backwards;

    .projects_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        margin-bottom: 2rem;
        .projects-container-react {
            width: 100%;
        .react-projects {
            display: flex;
            flex-direction: row;
                    #project-link {
                        display: none;
                    }
        
                                // base
                    .flip-card {
                        color: #555;
                        background-color: black;
                        text-align: center;
                        font-family: 'Roboto Mono';
                        position: relative;
                        border-radius: 16px;
                        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.7);

                        

                        
                        .border {
                            border: none;
                        }
                        h1 {
                            font-size: 3rem;
                            
                        }

                        h2 {
                            font-size: 2.5rem;
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }

                        .front,
                        .back {
                            display: block;
                            transition-duration: .5s;
                            transition-property: transform, opacity;
                        }
                        .front {
                            transform: rotateX(0deg);
                            display: flex;
                            
                        
                            
                            
                        }
                        .back {
                            position: absolute;
                            opacity: 0;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            transform: rotateX(-180deg);
                        }
                     

                        &:hover {
                          

                            .front {
                            
                            
                                transform: rotateX(180deg);
                                
                                opacity: 0;
                            }
                            .back {
                                opacity: 1;
                                transform: rotateX(0deg);
                            }
                        }
                    }


                    #project-one-home {
                        background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1) ), url('/assets/images/react-travel-site.PNG'); 
                            
                    }
                    #project-two-home {
                        background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1) ), url('/assets/images/restaurant-site.PNG'); 
                            
                    }
                    

                    // custom
                    .flip-card {
                        position: relative;
                        display: inline-block;
                        margin: 1rem 4em;
                        margin-left: 0;
                        width: 100%;
                        

                        .front,
                        .back {
                        display: flex;
                        flex-direction: column;
                        color: white;
                        width: inherit;
                        background-size: cover!important;
                        background-position: center!important;
                        height: 250px;
                        padding: 1em 2em;
                        border-radius: 10px;
                        p {
                            font-size: 1.5rem;
                            line-height: 160%;
                            color: #999;
                        }
                        }

                        .front {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }

                        .back {
                            background: #313131;
                        }
                    }
        }
    }
}


}







.back {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.projectOne {
    background-image: url('/assets/images/ProjectOne.PNG');
    background-position: center;
    background-size: cover;
    height: fit-content;
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}

.projectTwo {
    background-image: url('/assets/images/ProjectTwo.PNG');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}
.projectOne, .projectTwo {
    display: flex;
    align-items: flex-end;
    margin-right: 2rem;
    transition: 1s;
    z-index: 100;
    background-size: 110%;
}



.projectOne:hover, .projectTwo:hover {
    cursor: pointer;
    opacity: 1;
    background-size: 115%;
    transition: 1s;
}

.projectOne img, .projectTwo img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}


#project_one_title, #project_two_title {
    font-size: 3rem;
    color: #e0e0e0;
    text-align: flex-start;
    margin: 0;
    padding: 8px;
    padding-left: 8px;
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: 15px;
    width: 100%;
    transition: 1s;
    animation: fadeIn 1s 1.8s backwards;
}


#project-description {
    font-size: 2rem;
    padding: 0 1rem;
    color: #e0e0e0;
}

#projects-button {
    margin-top: 0rem;
    width: 40%;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
}



@media (max-width: 3000px) {
   

    #projects-button {
      font-size: 4rem;
    }

    // .projects-section {
    //     margin-left: 40%;
    // }
  }
@media (max-width: 2000px) {
   
    .container {
        flex-direction: row;
    }
    .home-page .text-zone {
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }

    .projects-section {
        height: fit-content;
    }

    .home-page h1 {
        // text-align: center;
        font-size: 8rem;
        line-height: 8rem;
    
    }
    #projects-button, #contact-button {
      font-size: 2rem;
    }

    // .projects-section {
    //     margin-left: 40%;
    // }
  }


  @media (max-width: 1600px) {
   
    .container {
        flex-direction: row;
    }
    .home-page h1 {
        font-size: 5rem;
        line-height: 6rem;
        text-align: left;
    }

    .home-page .text-zone {
        margin-left: 8%;
        width: 40%;
        
    }

    #projects-button {
        font-size: 2rem;
    }

    #contact-button {
        font-size: 2rem;
    }

    #projects-button {
        width: 50%;
    }

    .projects-section {
        width: 90%;
    }

    .projects-section .projects_container {
        width: 80%;

    }

    #project-one-flip-card {
        margin: 0;
        
    }
    #project-two {
        margin: 0;
        margin-left: 2rem;
    }


    }


  
@media (max-width:1378px) {
        .projects-section {
            
            // margin-left: 50%;
           
        }
        #project-one-home {
            width: inherit;
        }
        #project-two-home {
            width: inherit;
           display: none;
        }
        #react-projects-display {
            justify-content: center;
        }

        #project-one-flip-card {
            width: 100%;
        }
    }



@media (max-width:1008px) {
        #react-projects-display {
            flex-direction: column;
        }
        #project-two-home {
           display: inline-block;
        }
        .container {
            flex-direction: column;
          }
          .projects-section {
            margin-left: 0;
          }
          #project-one-home {
            width: 100%;
        }
        #project-two-home {
            width: 100%;
           
        }
        #projects-button {
            margin-bottom: 3rem;
            width: 60%;
        }
        .flat-button {
            margin-bottom: 3rem;
        }
        .home-page h1 {
            font-size: 8rem;
            margin-top: 0;
            line-height: normal;
            text-align: center;
        }

        .home-page .text-zone {
            width: 90%;
            margin: 0;
        }

       
    }


    @media (max-width: 950px) {
        .home-page .text-zone {
            margin-top: 5rem;
        }

        .home-page h1 {
            font-size: 7rem;
        }
        #project-title {
            font-size: 3rem;
        }
        #project-text {
            font-size: 2rem;
        }
    }

    @media (max-width:600px) {
        .home-page h1 {
            margin-top: 10rem;
            font-size: 5rem;
        }

        #project-title {
            font-size: 2rem;
        }

        #project-text {
            font-size: 1.5rem;
        }
        
        .projects-section .projects_container .projects-container-react .react-projects .flip-card {
            width: 100%;
        }
        .projects-section {
            width: 80vw;
        }

        #projects-button {
            width: 100%;
        }

        .projects-section .projects_container {
            width: 100%;
    
        }
    
        
    }
    @media (max-width:300px) {
        .home-page h1 {
            margin-top: 12rem;
            font-size: 3rem;
        }
        
        #project-title {
            font-size: 1.7rem;
        }

        #project-text {
            font-size: 1.3rem;
        }

        .projects-section .projects_container .projects-container-react .react-projects .flip-card h2 {
            font-size: 2rem;
        }

        .projects-section .projects_container .projects-container-react .react-projects .flip-card .front p, .projects-section .projects_container .projects-container-react .react-projects .flip-card .back p {
            font-size: 1.3rem;
        }

        .home-page .flat-button {
            margin: 0;
        }

        #contact-button {
            margin-bottom: 1rem;
        }
    }



