.container.about-page {
  display: flex;

}

#text-zone {
  position: static;
  transform: translateY(0);
 
  
}

.stage-cube-cont {
    width: 50%;
    height: 100%;
    // top: 0;
    padding-top: 0;
    
    position: relative;
    // right: 0;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    // margin-left: calc(50% - 100px);
  
  
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }



  
  @media (min-width: 2000px) {
    
      #cube-icons {
        font-size: 20rem;
      }
      .cubespinner {
      
      
      
        div {
          
          width: 400px;
          height: 400px;
          
        }
      
        .face1 {
          transform: translateZ(200px);
         
        }
        .face2 {
          transform: rotateY(90deg) translateZ(200px);
          
        }
        .face3 {
          transform: rotateY(90deg) rotateX(90deg) translateZ(200px);
          
        }
        .face4 {
          transform: rotateY(180deg) rotateZ(90deg) translateZ(200px);
          
        }
        .face5 {
          transform: rotateY(-90deg) rotateZ(90deg) translateZ(200px);
        
        }
        .face6 {
          transform: rotateX(-90deg) translateZ(200px);
          
        }
      }
      
    


  }
  @media (max-width: 1000px) {
      .stage-cube-cont {
           margin-bottom: 10rem;
           margin-right: 20rem;
      }
  }
  
  @media (max-width: 600px) {
    .container {
      display: flex;
      flex-direction: column;
    }
    .stage-cube-cont {
      position: static;
      height: 400px;
      width: 80%;
    }
    .container.contact-page .text-zone, .container.about-page .text-zone {
      position: static;
      transform: translateY(0);
      width: 100%;
      height: 100vh;
      
    }
  }


  @media (max-width: 470px) {
    .stage-cube-cont {
        height: 600px;
        margin-bottom: 25rem;
        margin-top: 5rem;
    }

  }

  @media (max-width: 400px) {
    .container.contact-page .text-zone, .container.about-page .text-zone {
      margin-top: 0;
      height: fit-content;
    }
    .stage-cube-cont {
      height: 300px;
      margin-bottom: 10rem;
      margin-top: 0;
  }

  }

  