.hamburger-icon {
  display: none;
}
.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 500px;
    -webkit-border-radius: 0;
  
    .logo {
      display: block;
      padding: 8px 0;
  
      img {
        display: block;
        margin: 8px auto;
        width: 24px;
        height: auto;
  
        &.sub-logo {
          width: 50px;
        }
      }
    }
  
    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;
  
      a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
  
        i {
          transition: all 0.3s ease-out;
        }
  
        &:hover {
          color: #ffd700;
  
          svg {
            opacity: 0;
          }
  
          &:after {
            opacity: 1;
          }
        }
  
        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
  
        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }
  
      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }
  
      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }
      a.work-link {
        &:after {
          content: 'WORK';
        }
      }
  
      a.active {
        svg {
          color: #ffd700;
        }
      }
    }
  
    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;
  
      li {

        a {
          padding: 7px 0;
          display: block;
          font-size: 15px;
          line-height: 16px;
          color: #4d4d4e;
  
          &:hover {
            color: #ffd700;
            
          }
        }
      }

      &:hover {
        color:#ffd700 ;
      }
    }

    .icon:hover {
      color: #ffd700;
    }
   
  }


 @media (min-width: 2000px) {
  .nav-bar {
    
    width: 4%;

    .svg-inline--fa {
      height: 4rem;
    }
  }

  .nav-bar .logo img {
    width: 40px;
  }

  .nav-bar nav a.active svg {
    height: 4rem;
  }

  
  .nav-bar nav a {
    margin-bottom: 4rem;
  }
  .nav-bar nav a:after {
    font-size: 2.3rem;
  }
 
}


.mobile-link {
  font-size: 4rem;
}








@media (max-width: 1008px) {
  .hamburger-icon {
    display: block;
}
  .mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .svg-inline--fa {
      font-size: 6rem;
      
      // margin-right: 4rem;
    }
  }
  .hamburger-icon {
    position: relative;
    height: 50px;
    width: 50px;
    color: #ffd700;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 2s;
  }

 .fas {
    height: 40px;
    width: 40px;
    color: white;
   
    position: fixed;
    z-index: 700;
    transition: 0.5s;
    display: inline;
  }

  .mobile-link {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 4rem;
    text-decoration: none;
    color: white;
    // border-bottom:  2px solid ;

    font-family: "Roboto Mono";
    
  }

  
   .mobile-nav-link-bars {
      position: fixed;
      top: 0;
      left: 0;
      // background-color: #181818;
      width: 100vw;
      height: 100vh;
      z-index: 699;
      transform: translateX(-100vw);
      transition: 0.5s;
    }

    .mobile-nav-link-times {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #181818;
      width: 100vw;
      height: 100vh;
      z-index: 699;
      transform: translateX(0);
      transition: 0.5s;
      
    }
}