.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

}



.home-page {
    .text-zone {
      width: fit-content;
      max-height: 90%;
    }
  
    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
  
   
     
    }
  
    h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }
  
    .flat-button {
      color: #ffd700;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #ffd700;
        color: #333;
      }
    }

    #ts-particles {
      transition: all 1s;
      animation: fadeIn 1s 1.8s backwards;
    }
  }
  

  @media (max-width: 3000px) {
    .home-page h1 {
      font-size: 10rem;
      line-height: 12rem;
    }

    #contact-button {
      font-size: 4rem;
    }

    #projects-button {
      font-size: 4rem;
    }
  }
  
  @media (max-width:1008px) {
    .container {
      flex-direction: column;
    }
    .projects-section {
      margin-left: 0;
    }

 
  .home-page .text-zone {
   
    transform: translateY(0);
    margin-top: 50%;
    font-size: 7rem;
  }

  
}
